<template>
  <screen-modal-inner
    v-if="show"
    @close="closeModal"
    v-bind="{ ...$props, ...$attrs }"
    ><slot></slot
  ></screen-modal-inner>
</template>

<script>
import { defineAsyncComponent } from "vue";
export default {
  name: "ScreenModal",
  components: {
    ScreenModalInner: defineAsyncComponent(() =>
      import("@/components/global/ScreenModalInner")
    )
  },
  methods: {
    closeModal() {
      this.$emit("close");
    }
  },
  props: {
    confirmAction: {
      required: true,
      type: Function
    },
    show: {
      required: false,
      type: Boolean
    },
    type: {
      required: true,
      type: String
    },
    confirmButtonLabel: {
      type: String,
      required: true
    },
    cancelButtonLabel: {
      type: String,
      required: true
    },
    hideTimer: {
      type: Boolean,
      required: false
    },
    preventConfirm: {
      type: Boolean,
      required: false
    }
  }
};
</script>
